import firebase from 'firebase'


// Firebase Configuration
const config = {
    apiKey: "AIzaSyDSIE2JDPz8WRGSP7ehqsIc5QMFh5JDr3Y",
    authDomain: "flikbox-2606.firebaseapp.com",
    projectId: "flikbox-2606",
    storageBucket: "flikbox-2606.appspot.com",
    messagingSenderId: "195515976423",
    appId: "1:195515976423:web:81a3bb84911f2d33183042",
    measurementId: "G-GG83DKG7QK"
};

// Firebase Initialization
//const firebaseApp = firebase.initializeApp(config);
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

// Defining Firestore Collections
const db = firebase.firestore();
const productsCollection = db.collection("products");
const reviewsCollection = db.collection("reviews");


// Function to get product data from firestore
export const getProductInfo = async (pid) => {
    let productData = {};
    
    await productsCollection.doc(pid).get()
    .then((doc) => {
        productData = doc.data();
    })
    .catch((error) => {
        return error
    });

    return productData
}

// Function to get product review data from firestore
export const getReviewInfo = async (rid) => {
    let reviewData = {};
    
    await reviewsCollection.doc(rid).get()
    .then((doc) => {
        reviewData = doc.data()["reviewData"];
    })
    .catch((error) => {
        return error
    });

    return reviewData
}


// Function to write review data in leads table
export const submitReview = async (id, data) => {
    const status = await reviewsCollection.doc(id).update({
        reviewData: firebase.firestore.FieldValue.arrayUnion(data)
      }).then(() => {
        return true
    });
    return status
}