<template>

    <!-- Product Body -->
    <div class="main-body">
        <!-- Breadcrumb -->
        <div class="breadcrumb-box" v-if="productData!=undefined">
          <span class="breadcrumb-text"><a href="/">Home</a></span><span class="breadcrumb-right">❯</span>
          <span class="breadcrumb-text"><a :href="'/collection/'+productData.collection[0]">{{productData.collection[0]}}</a></span><span class="breadcrumb-right">❯</span>
          <span class="breadcrumb-text breadcrumb-text-last">{{productData.name}}</span><span class="breadcrumb-right">❯</span>
        </div>

        <!-- Product Body -->
        <div class="product-body" v-if="productData!=undefined">
            <!-- Product Image -->
            <div class="product-image-container">

                <div v-for="i in 4" :key="i" class="image-grid-box">
                    <div class="image-grid-imageContainer" @click="showImage(i)">
                        <div class="image-grid-image" :style="getStyle(i)"></div>
                        <div class="image-grid-loader"></div>
                    </div>
                </div>
            </div>

            <!-- Product Info -->
            <div class="product-info-container">
                <!-- Title -->
                <h2 class="product-title">{{productData.name}}</h2>
                <!-- Rating & Review Info -->
                <div class="rating-review-info-box">
                    <span v-for="i in productRatingRounded" :key="i"><svg xmlns="http://www.w3.org/2000/svg" class="review-star" viewBox="0 0 24 24"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span>
                    <span><svg v-for="i in 5-productRatingRounded" :key="i" xmlns="http://www.w3.org/2000/svg" class="review-star-blank" viewBox="0 0 24 24"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span>
                    <span class="rating-val">{{productRating}}</span>
                    <a href="#user-reviews"><span class="see-all-reviews">See all reviews</span></a>
                </div>

                <!-- Price -->
                <p class="product-price">${{productData.price}}</p>
                
                <!-- Product Color Selector -->
				<ColorSelection :availableColors="productData.meta.availableColors" @color-selected="colorUpdated"/>

                <!-- Product Size Selector -->
				<SizeSelection :availableSizes="productData.meta.availableSizes" @size-selected="sizeUpdated"/>

                <!-- Add to Cart -->
                <div class="btn add-cart-btn" @click="addToCart()">
                    Add to Cart
                </div>

                <!-- Details -->
                <div class="product-description-container" v-for="item in productData.details" :key="item.name">
                    <span class="info-title">{{item.name}}</span>
                    <p class="info-description" v-if="item.type=='text'">{{item.value}}</p>
                    <ul v-if="item.type=='list'">
                        <li class="info-description" v-for="val in item.value" :key="val">
                            <p>{{val}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Size Guide -->
        <SizeGuide v-if="productData!=undefined"/>
        <!-- Review Container -->
        <UserReviews v-if="productData!=undefined" :productId="productData.id"/>
        <!-- Product Grid -->
        <div class="product-grid-header-box" v-if="productData!=undefined">
          <p class="product-grid-name">More from this Collection</p>
          <a v-if="productCollectionData.length>5" :href="'/collection/all'"><p class="product-grid-action">View all ➜</p></a>
        </div>
        <ProductGrid v-if="productCollectionData!=undefined&&productCollectionData.length>0" :productGridData="collectionGridList" :mainGridCount="5"/>
        <div class="product-grid-header-box" v-if="productData!=undefined">
          <p class="product-grid-name">People also bought</p>
        </div>
        <ProductGrid v-if="alsoBoughtData!=undefined&&alsoBoughtData.length>0" :productGridData="alsoBoughtData" :mainGridCount="5"/>
        <!-- Image Full View -->
        <ImageFullView ref="image-viewer" :selectedImage="selectedImage"/>
        
    </div>

</template>

<script>
import UserReviews from '@/components/product/UserReviews.vue'
import ProductGrid from '@/components/product/ProductGrid.vue'
import SizeSelection from '@/components/product/SizeSelection.vue'
import ColorSelection from '@/components/product/ColorSelection.vue'
import ImageFullView from '@/components/product/ImageFullView.vue'
import SizeGuide from '@/components/product/SizeGuide.vue'
import { getProductInfo } from "@/apis/product";
import { getCollectionFileUrl } from "@/apis/collection";
import { getCollectionFile } from "@/apis/collection";

export default {
    name: 'Product',
    components: { ColorSelection, SizeSelection, UserReviews, ProductGrid, ImageFullView, SizeGuide },
    data(){
        return{
            productData: undefined,
            productRating: 0.0,
            productRatingRounded: 0,
            selectedImage: undefined,
            productSize: undefined,
            productColor: undefined,
            cartItems: [],
            collectionsList: [],
            collectionName: undefined,
            productCollectionData: [],
            alsoBoughtData: [],
            collectionGridList: []
        }
    },
    mounted(){
        this.getProductInfoFromDatabase();
        this.getCartItems();
    },
    methods:{
        // Get Product Info from Database
        async getProductInfoFromDatabase(){
            this.productData = await getProductInfo(this.$route.params["id"]);
            console.log(this.productData);
            this.calculateProductRating();
            this.setDefaultProductValues();
            this.getCollection();
        },
        // Calculate Product Rating
        calculateProductRating(){
            if(this.productData["rating"]["totalRating"] > 0){
                this.productRating = (this.productData["rating"]["totalRating"]/this.productData["rating"]["ratingCount"]).toFixed(1);
                this.productRatingRounded = Math.round(parseFloat(this.productRating));
            }
        },
        // Set Product Image
        getStyle(i){
            return {"background": "url('https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F" + this.productData['id'] + '%2F'+ parseInt(i) + ".png?alt=media')", "background-position": "center"};
        },
        // Show Product Image on Click
        showImage(i){
            this.selectedImage = "https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F" + this.productData['id'] + '%2F'+ parseInt(i) + ".png?alt=media";
            this.$refs["image-viewer"].showModal();
        },
        // Get Cart Items from Local Storage
		getCartItems(){
            this.cartItems = JSON.parse(localStorage.getItem("cartItems"));
            if(this.cartItems == null){
                this.cartItems = [];
            }
        },
        // Get Collection Data from Firebase Storage
        async getCollection(){
            let currentTime = Math.floor(Date.now() / 1000);
            let collectionsLastUpdated = localStorage.getItem("collectionsLastUpdated");
            if((collectionsLastUpdated == null) || (currentTime-collectionsLastUpdated)>86400){
                let self = this;
                let url = await getCollectionFileUrl('assets/files/collection/all.json');
                this.collectionsList = await getCollectionFile(url);

                localStorage.setItem("collections", JSON.stringify(self.collectionsList));
                localStorage.setItem("collectionsLastUpdated", currentTime);
            }
            else{
                this.collectionsList = JSON.parse(localStorage.getItem("collections"));
            }
            
            this.getCollectionData();
        },
        getCollectionData(){
            this.collectionName = this.productData["collection"][0];
            var temp = [];

            for(let i in this.collectionsList){
                if(this.collectionsList[i]["collection"].includes(this.collectionName) && (!temp.includes(this.collectionsList[i])) ){
                    temp.push(this.collectionsList[i]);
                }
            }
            this.productCollectionData = temp;

            // Shuffle and limit to 5 products
            this.collectionGridList = this.productCollectionData.map((value) => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value).slice(0, 5);


            var arr = [];
            while(arr.length < 5){
                var r = Math.floor(Math.random() * this.collectionsList.length) + 1;
                if(arr.indexOf(r) === -1){
                    arr.push(r);
                    if(this.collectionsList[r] !=undefined){
                        this.alsoBoughtData.push(this.collectionsList[r]);
                    }
                }
            }
        },
        setDefaultProductValues(){
            let availableSizes = this.productData["meta"]["availableSizes"];
            for(let i in availableSizes){
                if(availableSizes[i]["status"]){
                    this.productSize = availableSizes[i]["name"];
                    break;
                }
            }
            this.productColor = this.productData["meta"]["availableColors"][0]["name"];
        },
        // Update Product Size
        sizeUpdated(val){
            this.productSize = val;
        },
        // Update Product Size
        colorUpdated(val){
            this.productColor = val;
        },
        // Add Product to Cart
        addToCart(){
            let newItem = {"id": this.productData.id, "name": this.productData.name, "price": this.productData.price, "size": this.productSize, "color": this.productColor, "quantity": 1}
            
            let found = this.cartItems.some(el => (el.id === newItem.id) && (el.size === newItem.size) && (el.color === newItem.color));
            if (!found){
                this.cartItems.push(newItem);
            }
            else{
                for(let i=0;i<this.cartItems.length;++i){
                    if((this.cartItems[i]["id"] == newItem.id) && (this.cartItems[i]["size"] == newItem.size) && (this.cartItems[i]["color"] == newItem.color)){
                        this.cartItems[i]["quantity"] = parseInt(this.cartItems[i]["quantity"]) + 1;
                    }
                }
            }

            localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
            this.$root.$refs['navbar'].$refs["mini-cart"].getCartItems();
            this.$root.$refs['navbar'].$refs["mini-cart"].openMiniCart();
            this.$root.$refs['navbar'].getCartItems();
        }
    }
}
</script>

<style scoped>
    /* Product Body */
    .main-body{max-width: 1280px;margin: auto;padding: 0 12px;min-height: 100vh;position: relative;}
    /* Breadcrumb */
    .breadcrumb-box{position: relative;margin: 24px 0px;display: flex;}
    .breadcrumb-text{font-size: 14px;font-weight: 500;cursor: pointer;transition: 0.2s all;text-transform: capitalize;}
    .breadcrumb-text-last{overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;word-break: break-all;max-width: 160px;}
    .breadcrumb-text:hover{text-decoration: underline;}
    .breadcrumb-text-last:hover{text-decoration: none !important;}
    .breadcrumb-right{font-size: 13px;margin: 0 10px;color: #a7a7a7;}
    .breadcrumb-right:last-child{display: none;}
    /* Product */
    .product-body{display: grid;grid-template-columns: 1fr 420px;grid-gap: 96px;margin-top: 32px;}
    /* Product Image */
    .product-image-container{display: grid;grid-template-columns: 1fr 1fr;grid-auto-rows: max-content;}
    .image-grid-box{margin-bottom: 4px;position: relative;cursor: pointer;margin-right: 4px;}
    .image-grid-imageContainer {height: 0;padding-top: 133.33333333333331%;overflow: hidden;position: relative;border: 1px solid #f5f5f6;}
    .image-grid-image {position: absolute;top: 0;left: 0;z-index: 2;width: 100%;height: 100%;background-repeat: no-repeat;background-position: 0 0;background-origin: border-box;background-size: cover !important;-webkit-transition: -webkit-transform .4s;transition: -webkit-transform .4s;transition: transform .4s;transition: transform .4s,-webkit-transform .4s;will-change: transform;}
    .image-grid-image:hover{-webkit-transform:scale(1.04);transform:scale(1.04)}
    .image-grid-loader{position:absolute;z-index:1;top:0;left:0;width:99.99%;height:99.99%;background:#eaeaec linear-gradient(to right,#eaeaec,#dad9d9 35%,#eaeaec 70%,#eaeaec) no-repeat;background:#eaeaec -webkit-gradient(linear,left top,right top,color-stop(0,#eaeaec),color-stop(35%,#dad9d9),color-stop(70%,#eaeaec),to(#eaeaec)) no-repeat;background-size:800px 100%;-webkit-animation:image-grid-loading-skeleton 1.7s cubic-bezier(.25,.46,.45,.94) infinite forwards;animation:image-grid-loading-skeleton 1.7s cubic-bezier(.25,.46,.45,.94) infinite forwards}@-webkit-keyframes image-grid-loading-skeleton{0%{background-position:-468px 0}to{background-position:468px 0}}@keyframes image-grid-loading-skeleton{0%{background-position:-468px 0}to{background-position:468px 0}}
    /* Product Info */
    .rating-review-info-box{margin: 2px 0px;}
    .review-star{fill: #ffbc59;margin: 1px 2px;width: 16px;height: 16px;cursor: pointer;display: inline-block;vertical-align: top;}
    .review-star-blank{fill: #cecece;margin: 1px 2px;width: 16px;height: 16px;cursor: pointer;display: inline-block;vertical-align: top;}
    .rating-val{font-size: 14px;margin: 0 4px;margin-right: 12px;font-weight: 500;color: #3a3a3a;}
    .see-all-reviews{color: #6366f1;font-size: 14px;font-weight: 500;padding-left: 12px;border-left: 1px solid #9ba3af;cursor: pointer;}
    .see-all-reviews:hover{text-decoration: underline;}
    .product-price{font-weight: 500;font-size: 21px;line-height: 30px;margin: 16px 0px;}
    
    
    /* Product Size */
    .product-size-container{font-size: 14px;font-weight: 400;color: #a0a4a8;display: inline-block;width: 100%;}
    .product-size-name{font-weight: 500;color: #3a3a3a;text-transform: uppercase;font-size: 13px;}
    .size-guide-btn{float: right;color: #6366f1;font-size: 14px;font-weight: 500;padding-left: 12px;cursor: pointer;}
    .size-guide-btn:hover{text-decoration: underline;}
    .product-size-box{display: grid;margin: 10px 0;grid-gap: 6px;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;justify-items: left;}
    .product-size-item{width: 54px;height: 42px;line-height: 24px;text-align: center;padding: 8px;color: #3a3a3a;font-weight: 500;border: 1px solid #cecece;background-color: #fff;border-radius: 6px;cursor: pointer;user-select: none;box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 5%);}
    .product-size-item-active{background-color: #6366f1;color: #fff;border: 1px solid #5659d2;}
    .add-cart-btn{margin: 12px 0;width: 100%;padding: 8px 16px;border-radius: 6px;font-size: 15px;font-weight: 400;}
    /* Product Description */
    .product-description-container{margin: 32px 0;}
    .product-description-title{font-weight: 500;color: #3a3a3a;font-size: 14px;text-transform: uppercase;}
    .description-text{font-size: 15px;margin: 12px 0;line-height: 1.5;font-weight: 400;color: #868686;}
    .product-description-container ul{list-style: initial;padding-left: 18px;margin: 12px 0;}
    .product-description-container ul li{margin: 6px 0;}
    /* Add to Cart Button */
    .add-cart-btn{margin: 12px 0;width: 100%;padding: 8px 16px;border-radius: 6px;font-size: 15px;font-weight: 400;}

    /* Product Description */
    .product-description-container{margin: 32px 0;}
    .info-title{font-weight: 500;color: #3a3a3a;font-size: 14px;text-transform: uppercase;}
    .info-description{font-size: 15px;margin: 12px 0;line-height: 1.5;font-weight: 400;color: #868686;}
    .product-info-container ul{list-style: initial;padding-left: 18px;margin: 12px 0;}
    .product-info-container ul li{margin: 6px 0;}

    .product-grid-header-box{max-width: 1280px;margin: auto;padding: 0px 12px;padding-bottom: 16px;padding-top: 72px;}
    .product-grid-name{font-size: 20px;font-weight: 700;padding: 4px 0;display: inline-block;line-height: 24px;}
    .product-grid-action{margin: 0px;line-height: 24px;font-size: 14px;font-weight: 500;color: #3e50b5;user-select: none;padding: 4px 0;float: right;cursor: pointer;}

    /* Tablet */
    @media only screen and (max-width: 1024px){
      .product-body{grid-gap: 48px;}
    }

    /* Tablet & Portrait */
    @media only screen and (max-width: 1024px) and (orientation: portrait){
      .product-body{max-width: 500px;margin: auto;display: block;}
      .product-info-container{margin-top: 24px;}
      .add-cart-btn{display: block;}
      .product-description-container{max-width: 500px;}
    }

    /* Device under 900px & Landscape */
    @media only screen and (max-width: 900px) and (orientation: landscape){
      .product-body{max-width: 500px;margin: auto;display: block;}
      .product-info-container{margin-top: 24px;}
    }
</style>