<template>
    <div class="modal-wrapper" :style="modalStyle" @click="hideModal()">
        <img @click.stop v-if="selectedImage!=undefined" class="center current-image" :src="selectedImage">

        <svg height="18" width="18" viewBox="0 0 24 24" aria-hidden="true" aria-label="" role="img" class="close-icon"><path d="M15.18 12l7.16-7.16c.88-.88.88-2.3 0-3.18-.88-.88-2.3-.88-3.18 0L12 8.82 4.84 1.66c-.88-.88-2.3-.88-3.18 0-.88.88-.88 2.3 0 3.18L8.82 12l-7.16 7.16c-.88.88-.88 2.3 0 3.18.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66L12 15.18l7.16 7.16c.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66.88-.88.88-2.3 0-3.18L15.18 12z"></path></svg>
    </div>
</template>

<script>
export default {
    name: 'ImageFullView',
    data(){
        return{
            modalStyle: {}
        }
    },
    props:{
        selectedImage: {
            type: String
        }
    },
    watch: {
        $route() {
            if(window.location.hash != "#image-zoomed"){
                this.hideModal();
            }
        }
    },
    methods:{
        // Show Image
        showModal(){
            window.location.href = "#image-zoomed"
            this.modalStyle = {"opacity": 1, "visibility": "visible"}
        },
        // Hide Image
        hideModal(){
            this.modalStyle = {}
            history.pushState("", document.title, window.location.pathname + window.location.search);
        }
    }
}
</script>

<style scoped>
    .modal-wrapper{position: fixed;width: 100%;height: 100vh;background-color: rgba(0, 0, 0, 0.72);top: 0;left: 0;z-index: 11;opacity: 0;visibility: hidden;cursor: pointer;}
    .current-image{max-height: calc(98% - 56px);max-width: 98%;cursor: default;background-color: #fff;border-radius: 4px;}
    .close-icon{position: absolute;top: 10px;right: 12px;fill: #fff;cursor: pointer;}
</style>