<template>

    <!-- Size Guide -->
    <div class="size-guide-instructions-box" id="size-guide">
        <h3>Size Guide</h3>
        <div class="size-guide-info-wrapper">
            <p class="size-guide-description">All measurements in the table refer to garment sizes.</p>
            <div class="size-guide-container">
                <div class="size-toggle-box">
                    <span class="toggle-item toggle-item-active" :style="imperialStyle" @click="toggleSizeGuide('imperial')">Imperial</span>
                    <span class="toggle-item" :style="metricStyle" @click="toggleSizeGuide('metric')">Metric</span>
                </div>
                
                <div v-for="elem in sizeGuideData" :key="elem.system">
                    <div class="table" v-if="activeSizeGuide==elem.system">
                        <div v-for="item in elem.data" :key="item" :class="[item.isHeader ? 'header' : 'row type']">
                            <div class="item" v-for="val in item.value" :key="val">{{val}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'SizeGuide',
    data(){
        return{
            imperialStyle: {'border-bottom': '2px solid #6366f1', 'color': '#6366f1', 'font-weight': '600'},
            metricStyle: {'color': '#000'},
            activeSizeGuide: 'imperial',
            sizeGuideData: [{"system":"imperial","data":[{"isHeader":true,"value":["","S","M","L","XL","2XL","3XL","4XL","5XL"]},{"isHeader":false,"value":["Width, in","18.51","20.52","22.52","24.49","26.5","28.51","30.52","32.52"]},{"isHeader":false,"value":["Length, in","28.51","29.49","30.52","31.5","32.52","33.51","34.49","35.52"]},{"isHeader":false,"value":["Sleeve length, in","7.01","7.49","8","8.39","8.86","9.77","10.24","10.75"]}]},{"system":"metric","data":[{"isHeader":true,"value":["","S","M","L","XL","2XL","3XL","4XL","5XL"]},{"isHeader":false,"value":["Width, in","47","52.1","57.2","62.2","67.3","72.4","77.5","82.6"]},{"isHeader":false,"value":["Length, in","72.4","74.9","77.5","80","82.6","85.1","87.6","90.2"]},{"isHeader":false,"value":["Sleeve length, in","17.8","19","20.3","21.3","22.5","24.8","26","27.3"]}]}],
        }
    },
    methods:{
        toggleSizeGuide(val){
            this.activeSizeGuide = val;
            if(val == "imperial"){
                this.imperialStyle = {'border-bottom': '2px solid #6366f1', 'color': '#6366f1', 'font-weight': '600'}
                this.metricStyle = {'color': '#000'}
            }
            else{
                this.metricStyle = {'border-bottom': '2px solid #6366f1', 'color': '#6366f1', 'font-weight': '600'}
                this.imperialStyle = {'color': '#000'}
            }
        }
    }
}
</script>

<style scoped>
    /* Size Guide Css */
    .size-guide-instructions-box{padding-top: 72px;}
    .size-guide-description{font-size: 15px;line-height: 1.4;font-weight: 400;color: #868686;padding: 8px 0 16px 0;}
    .table{display: flex;flex-flow: column nowrap;justify-content: space-between;}
    .header{font-weight: 500;color: #000;border-top: 1px solid #ededed;display: flex;flex-flow: row nowrap;width: 100%;white-space: nowrap;}
    .item{position: relative;flex-flow: row;flex-grow: 1;flex-basis: 0;padding: 16px 0 12px;font-size: 14px;color: #404145;}
    .row{display: flex;flex-flow: row nowrap;width: 100%;border-top: 1px solid #ededed;}
    .size-toggle-box{margin: 14px 0;border-bottom: 1px solid transparent;}
    .toggle-item{width: 84px;padding-bottom: 12px;display: inline-block;border-bottom: 1px solid #ededed;text-align: center;cursor: pointer;text-decoration: none;outline: none;font-weight: 500;color: #3a3a3a;font-size: 14px;text-transform: uppercase;}
    .header .item{color: #949494;}

    /* Tablet */
    @media only screen and (max-width: 1023px){
        .size-guide-instructions-box{padding-top: 48px;max-width: 500px;margin: auto;display: block;}
        .table{flex-flow: row;justify-content: flex-start;}
		.header{flex-direction: column;width: 30%;}
		.row{flex-direction: column;width: 30%;}
		.item{white-space: nowrap;}
    }

</style>