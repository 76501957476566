<template>

    <!-- Add Review -->
    <div class="modal-wrapper" :style="modalStyle" @click="hideModal()">
        <svg height="18" width="18" viewBox="0 0 24 24" aria-hidden="true" aria-label="" role="img" class="close-icon"><path d="M15.18 12l7.16-7.16c.88-.88.88-2.3 0-3.18-.88-.88-2.3-.88-3.18 0L12 8.82 4.84 1.66c-.88-.88-2.3-.88-3.18 0-.88.88-.88 2.3 0 3.18L8.82 12l-7.16 7.16c-.88.88-.88 2.3 0 3.18.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66L12 15.18l7.16 7.16c.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66.88-.88.88-2.3 0-3.18L15.18 12z"></path></svg>
        <div @click.stop class="modal-box center">
            <h3 class="add-review-header">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z"/></svg>
                </span>
                Add Review
            </h3>

            <label class="review-label">Your Rating</label>
            <div>
                <span v-for="i in userRating" :key="i"><svg xmlns="http://www.w3.org/2000/svg" @click="updateUserRating(i)" class="review-star" viewBox="0 0 24 24"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span>
                <span><svg v-for="i in 5-userRating" :key="i" xmlns="http://www.w3.org/2000/svg" @click="updateUserRating(i+userRating)" class="review-star-blank" viewBox="0 0 24 24"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span>
            </div>

            <label for="review-title" class="review-label">Review Title</label>
            <input type="text" class="input-box" name="review-title" placeholder="Add a review title" maxlength="100" v-model="reviewTitle">

            <label for="review-details" class="review-label">Detailed Review</label>
            <textarea name="review-details" id="review-details" class="textarea" placeholder="Add detailed review" maxlength="1000" v-model="reviewDescription"></textarea>

            <div class="review-action-box">
                <div class="outlined-button" @click="hideModal()"><p class="outlined-button-text cancel-review-button">Cancel</p></div>
                <p class="btn submit-review-btn" @click="submitReviewInfo()">Submit Review</p>
            </div>
        </div>
    </div>

</template>

<script>
import { submitReview } from "@/apis/product";

export default {
    name: 'AddReview',
    data(){
        return{
            modalStyle: {},
            userRating: 0,
            reviewTitle: '',
            reviewDescription: '',

        }
    },
    methods:{
        // Show Modal
        showModal(){
            this.modalStyle = {"visibility": "visible", "opacity": 1}
        },
        // Hide Modal
        hideModal(){
            this.modalStyle = {}
        },
        updateUserRating(i){
            this.userRating = i;
        },
        // Add review to database
        async submitReviewInfo(){
            var data = {"name":"John Paul","rating":this.userRating,"review":{"title":this.reviewTitle,"description":this.reviewDescription}}
            var status = await submitReview("essential-white-t-shirt", data);
            console.log(status);
            this.hideModal();
        }
    }
}
</script>

<style scoped>
    .modal-wrapper{position: fixed;width: 100%;height: 100vh;background-color: rgba(0, 0, 0, 0.72);top: 0;left: 0;z-index: 10;cursor: pointer;transition-property: all;transition-timing-function: cubic-bezier(0.4,0,0.2,1);transition-duration: 150ms;visibility: hidden;opacity: 0;}
    .modal-box{width: 94%;max-width: 512px;background-color: #fff;border-radius: 8px;box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);padding: 24px;}
    .close-icon{position: absolute;top: 10px;right: 12px;fill: #fff;cursor: pointer;}
    .add-review-header{text-align: center;margin-bottom: 12px;}
    .add-review-header span{vertical-align: text-bottom;margin-right: -2px;}
    .review-star{fill: #ffbc59;margin: 1px 2px;width: 16px;height: 16px;cursor: pointer;display: inline-block;vertical-align: top;}
    .review-star-blank{fill: #cecece;margin: 1px 2px;width: 16px;height: 16px;cursor: pointer;display: inline-block;vertical-align: top;}

    .review-label{margin-top: 12px;position: relative;display: block;padding: 8px 4px;padding-bottom: 4px;font-size: 14px;font-weight: 500;}

    .review-action-box{display: grid;grid-template-columns: 1fr 1fr;margin-top: 24px;grid-gap: 16px;}
    .cancel-review-button{padding: 10px 16px;}
    .submit-review-btn{margin: 0px;padding: 7px 16px;}
</style>