<template>
    <!-- Color Selection Component -->
    <div class="color-container-wrapper">
        <span class="color-name-title">Color: </span><span class="color-name-value">{{availableColors[activeColor]["name"]}}</span>
        <div class="color-input-box-wrapper">
            <label v-for="item, index in availableColors" :key="index" class="color-label" :class="{ 'color-label-active' : activeColor == index}" @click="toggleActive(index)">
                <input class="color-radio-input" type="radio" name="color-choice" :value="item.name">
                <span class="color-span" :style="'background-color: '+item.color"></span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ColorSelection',
    data(){
        return{
            activeColor: 0
        }
    },
    props:{
        availableColors: {}
    },
    methods:{
        // Toggle Color
        toggleActive(val){
            if(this.activeColor != val){
                this.activeColor = val;
                this.$emit("color-selected", this.availableColors[val]["name"]);
            }
            
        }
    }
}
</script>

<style scoped>
    .color-name-title{margin: 32px 0;font-size: 14px;font-weight: 400;color: #a0a4a8;}
    .color-name-value{font-weight: 500;color: #3a3a3a;text-transform: uppercase;font-size: 13px;}
    .color-input-box-wrapper{display: flex;align-items: center;grid-gap: 12px;margin-top: 12px;}
    .color-label{padding: 2px;border-radius: 100%;justify-content: center;align-items: center;cursor: pointer;display: flex;margin: -2px;position: relative;}
    .color-label-active{box-shadow: 0 0 0 0px #fff, 0 0 0 3px #6366f1;}
    .color-radio-input{-webkit-appearance: none;-moz-appearance: none;appearance: none;padding: 0;-webkit-print-color-adjust: exact;color-adjust: exact;display: inline-block;vertical-align: middle;background-origin: border-box;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;flex-shrink: 0;color: #2563eb;background-color: #fff;border-color: #6b7280;position: absolute;width: 1px;height: 1px;padding: 0;margin: -1px;overflow: hidden;clip: rect(0,0,0,0);white-space: nowrap;border-width: 0;}
    .color-span{width: 26px;height: 26px;border-radius: 100%;border: 1px solid #e8e8e8;}
</style>