<template>
    <!-- Product Reviews -->
    <div class="product-review-box-wrapper" id="user-reviews">
          <h3 class="review-header">Customer Reviews</h3>
          <div class="product-review-box" v-if="reviewData!=undefined">
            <div class="review-info-box">
              <div v-if="averageRatingRounded">
                <span v-for="i in averageRatingRounded" :key="i"><svg xmlns="http://www.w3.org/2000/svg" class="review-star" viewBox="0 0 24 24"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span>
                <span><svg v-for="i in 5-averageRatingRounded" :key="i" xmlns="http://www.w3.org/2000/svg" class="review-star-blank" viewBox="0 0 24 24"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span>
                <span v-if="hasRating" class="rating-val">{{averageRating}}</span>
                <span v-if="hasRating" class="rating-info-text">Based on {{reviewData.length}} reviews</span>
              </div>


              <div class="rating-distribution-box-wrapper">
                <div class="rating-distribution-box" v-for="item in ratingDistributionData" :key="item.name">
                  <span class="rating-val">{{item["name"]}}</span> <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="review-star"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span> <div class="rating-progress-wrapper">
                    <div class="rating-progress-bar" :style="'width: '+item['percentage']"></div>
                  </div>
                  <span v-if="hasRating" class="rating-percentage">{{item["percentage"]}}</span>
                  <span v-if="!hasRating" class="rating-percentage">0%</span>
                </div>
              </div>

              <div class="write-review-info-wrapper">
                <p class="write-review-header">Share your thoughts</p>
                <p class="write-review-description">If you have used this product, share your thoughts with other customers</p>
                <div class="outlined-button" @click="showAddReviewModal()">
                  <p class="outlined-button-text">Write a review</p>
                </div>
              </div>

            </div>
            <div class="review-list-box">
              <p v-if="!hasRating" class="center no-reviews-text">No Reviews yet!</p>
              <ul v-if="hasRating">
                <li v-for="review in reviewData" :key="review.name">
                  <div class="reviewer-details-box">
                    <svg xmlns="http://www.w3.org/2000/svg" class="reviewer-img" fill="none" viewBox="0 0 24 24" stroke="#a0a4a9"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" stroke="#ececec" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                    <div>
                      <p class="reviewer-name">{{review.name}}</p>
                      <div class="reviewer-rating">
                        <span v-for="i in review.rating" :key="i"><svg xmlns="http://www.w3.org/2000/svg" class="review-star" viewBox="0 0 24 24"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span>
                        <span><svg v-for="i in 5-review.rating" :key="i" xmlns="http://www.w3.org/2000/svg" class="review-star-blank" viewBox="0 0 24 24"><path d="M23 9.56a1 1 0 00-.87-.69l-6.6-.54-2.62-5.74A1 1 0 0012 2a1 1 0 00-.92.59L8.52 8.33l-6.6.54a1 1 0 00-.55 1.77l5 4.07-1.52 6.05a1 1 0 001.47 1.11L12 18.62l5.68 3.25a1 1 0 00.5.13 1 1 0 00.6-.2 1 1 0 00.37-1l-1.48-6.05 5-4.07A1 1 0 0023 9.56z"></path></svg></span>
                        <span class="rating-val">{{review.rating}}</span>
                      </div>
                    </div>
                  </div>

                  <p class="review-title">{{review.review.title}}</p>
                  <p class="review-description">{{review.review.description}}</p>

                </li>
              </ul>
            </div>
          </div>

          <AddReview ref="add-review"/>

        </div>
</template>

<script>
import AddReview from '@/components/product/AddReview.vue'
import { getReviewInfo } from "@/apis/product";

export default {
    name: 'UserReviews',
    components: { AddReview },
    data(){
      return{
            reviewData: undefined,
            ratingDistributionData: [],
            totalRating: 0,
            averageRating: undefined,
            averageRatingRounded: undefined,
            hasRating: false
      }
    },
    props:{
      productId: {
        type: String
      }
    },
    mounted(){
      this.getReviews();
    },
    methods:{
      // Get Product Reviews
      async getReviews(){
        this.reviewData = await getReviewInfo(this.productId);
        if(this.reviewData[0] == undefined){
          this.hasRating = false;
        }
        else{
          this.hasRating = true;
        }
        this.calculateRating();
      },
      // Calculate Average Rating
      calculateRating(){
        for(var i in this.reviewData){
          this.totalRating+=this.reviewData[i]["rating"];
        }
        this.averageRating = (this.totalRating/this.reviewData.length).toFixed(1);
        this.averageRatingRounded = Math.round(parseFloat(this.averageRating));
        this.calculateRatingDistribution();
      },
      // Calculate Rating Distribution
      calculateRatingDistribution(){
        let fiveStarCount = 0;
        let fourStarCount = 0;
        let threeStarCount = 0;
        let twoStarCount = 0;
        let oneStarCount = 0;
        for(var i in this.reviewData){
          if(this.reviewData[i]["rating"] == 5){
            fiveStarCount+=1;
          }
          else if(this.reviewData[i]["rating"] == 4){
            fourStarCount+=1;
          }
          else if(this.reviewData[i]["rating"] == 3){
            threeStarCount+=1;
          }
          else if(this.reviewData[i]["rating"] == 2){
            twoStarCount+=1;
          }
          else if(this.reviewData[i]["rating"] == 1){
            oneStarCount+=1;
          }
        }
        this.ratingDistributionData.push({"name": 5, "percentage": (fiveStarCount*100/this.reviewData.length).toFixed(0)+"%"})
        this.ratingDistributionData.push({"name": 4, "percentage": (fourStarCount*100/this.reviewData.length).toFixed(0)+"%"})
        this.ratingDistributionData.push({"name": 3, "percentage": (threeStarCount*100/this.reviewData.length).toFixed(0)+"%"})
        this.ratingDistributionData.push({"name": 2, "percentage": (twoStarCount*100/this.reviewData.length).toFixed(0)+"%"})
        this.ratingDistributionData.push({"name": 1, "percentage": (oneStarCount*100/this.reviewData.length).toFixed(0)+"%"})
        
      },
      showAddReviewModal(){
        this.$refs["add-review"].showModal();
      }
    }
}
</script>

<style scoped>
    .product-review-box-wrapper{padding-top: 96px;}
    .product-review-box{display: grid;grid-template-columns: 2fr 5fr;grid-gap: 96px;min-height: 300px;margin-top: 32px;}
    .rating-info-text{font-size: 14px;}
    .rating-distribution-box-wrapper{margin: 24px 0px;}
    .rating-distribution-box{display: flex;margin: 16px 0;}
    .review-star{fill: #ffbc59;margin: 1px 2px;width: 16px;height: 16px;cursor: pointer;display: inline-block;vertical-align: top;}
    .review-star-blank{fill: #cecece;margin: 1px 2px;width: 16px;height: 16px;cursor: pointer;display: inline-block;vertical-align: top;}
    .rating-val{font-size: 14px;margin: 0 4px;margin-right: 12px;font-weight: 500;color: #3a3a3a;}
    .rating-distribution-box .rating-val{margin: 0px;line-height: 18px;font-weight: 500;margin-right: 4px;}
    .rating-progress-wrapper{width: 100%;height: 12px;background-color: #f3f4f6;border-radius: 14px;border: 1px solid #eaeaea;position: relative;margin: 2px 8px;}
    .rating-progress-bar{background-color: #ffbc59;position: absolute;width: 0%;height: 12px;left: -1px;top: -1px;border-radius: 14px;}
    .rating-percentage{font-size: 14px;margin-left: 4px;line-height: 18px;width: 21px;}

    .write-review-info-wrapper{margin: 32px 0px;}
    .write-review-header{font-size: 18px;font-weight: 500;}
    .write-review-description{font-size: 15px;margin: 16px 0;line-height: 1.3;font-weight: 400;color: #868686;}

    .review-list-box{position: relative;}
    .no-reviews-text{line-height: 24px;font-size: 14px;font-weight: 500;top: 30%;}
    .review-list-box ul li{padding-bottom: 30px;margin-bottom: 30px;border-bottom: 1px solid #f1f1f1;}
    .review-list-box ul li:last-child{border-bottom: none;}
    .review-title{font-weight: 500;color: #3a3a3a;font-size: 15px;}
    .review-description{font-size: 15px;margin-top: 8px;line-height: 1.4;font-weight: 400;color: #868686;}
    .reviewer-details-box{display: flex;margin-bottom: 20px;grid-gap: 12px;}
    .reviewer-img{padding: 7px;width: 32px;height: 32px;background-color: #868686;border-radius: 100%;}
    .reviewer-name{font-weight: 600;color: #3a3a3a;font-size: 14px;line-height: 1;}
    .reviewer-rating{display: flex;margin-top: 2px;}
    .reviewer-rating .rating-val{line-height: 18px;display: inline-block;margin-left: 4px;}

    @media only screen and (max-width: 1024px){
      .product-review-box{grid-gap: 48px;}
    }

    @media only screen and (max-width: 1024px) and (orientation: portrait){
      .product-review-box-wrapper{max-width: 500px;margin: auto;margin-top: 96px;}
      .product-review-box{display: block;}
      .review-list-box{margin-top: 48px;}
    }

    @media only screen and (max-width: 900px) and (orientation: landscape){
      .product-review-box-wrapper{max-width: 500px;margin: auto;margin-top: 96px;}
      .product-review-box{display: block;}
    }
</style>