<template>
    <!-- Size Selection Component -->
    <div class="size-container-wrapper">
        <span class="size-name-title">Size: </span><span class="size-name-value">{{availableSizes[activeSize]["name"]}}</span>
        <a href="#size-guide"><p class="size-guide-btn">Size Guide</p></a>
        <div class="product-size-box">
            <div class="product-size-item" @click="toggleActive(index)" :class="getClass(index)" v-for="item, index in availableSizes" :key="item.name">{{item.name}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SizeSelection',
    data(){
        return{
            activeSize: 0
        }
    },
    props:{
        availableSizes: {}
    },
    methods:{
        // Toggle Size
        toggleActive(val){
            if(this.availableSizes[val]["status"]){
                this.activeSize = val;
                this.$emit("size-selected", this.availableSizes[val]["name"]);
            }
        },
        // Update Class when Toggled
        getClass(index){
            if(this.availableSizes[index]["status"]){
                if(this.activeSize == index){
                    return "product-size-item-active"
                }
            }
            else{
                return "product-size-disabled"
            }
            
        }
    }
}
</script>

<style scoped>
    .size-container-wrapper{margin-top: 24px;}
    .size-name-title{margin: 32px 0;font-size: 14px;font-weight: 400;color: #a0a4a8;}
    .size-name-value{font-weight: 500;color: #3a3a3a;text-transform: uppercase;font-size: 14px;}
    .size-guide-btn{float: right;color: #6366f1;font-size: 14px;font-weight: 500;padding-left: 12px;cursor: pointer;}
    .size-guide-btn:hover{text-decoration: underline;}

    .product-size-box{display: grid;margin: 10px 0;grid-gap: 6px;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;justify-items: left;}
    .product-size-item{width: 54px;height: 42px;line-height: 24px;text-align: center;padding: 8px;color: #3a3a3a;font-weight: 500;border: 1px solid #cecece;background-color: #fff;border-radius: 6px;cursor: pointer;user-select: none;box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 5%);}
    .product-size-item-active{background-color: #6366f1;color: #fff;border: 1px solid #5659d2;}
    .product-size-disabled{opacity: 0.4;cursor: not-allowed;}

    @media only screen and (max-width: 1024px) and (orientation: portrait){
        .size-container-wrapper{width: unset;}
        .product-size-box{display: inline-flex;}
        .product-size-item{width: 54px;}
    }

    @media only screen and (max-width: 600px){
      .product-size-box{display: grid;}
      .product-size-item{width: 100%;}
    }

    @media only screen and (max-width: 900px) and (orientation: landscape){
      .product-size-box{display: grid;}
      .product-size-item{width: 100%;}
    }
    
</style>